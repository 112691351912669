/* body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} */

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Montserrat', sans-serif;
}
.navbar-default {
	background-color: #393839;
	border-color: #393839;
}
.navbar-brand {
	padding: 4px 15px;
}
.navbar-brand img {
		max-height: 100%;
}

#intro .carousel-inner .item {
	background-position: center;
}
#intro .btn-get-started {
	border-color: #6abf4b;
}
#intro .btn-get-started:hover {
	background: #6abf4b;
}
.service .carousel-indicators {
	background: #f8f8f8;
}
.team-section {
	display: flex;
	display: -ms-flexbox;
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;
}
.team-section div[class*=col-] {
	float: none!important;
	width: 100%;
}
.team-section .col-xs-12 {
	max-width: 100%;
	flex: 0 0 100%;
	-ms-flex: 0 0 100%;
}
.box-team {
	text-align: center;
	margin-bottom: 2em;
}
@media only screen and (min-width: 768px) {
	.team-section .col-sm-6 {
		flex: 0 0 50%;
		-ms-flex: 0 0 50%;
		max-width: 50%;
	}
}

@media only screen and (min-width: 992px) {
	.team-section .col-md-4 {
		flex: 0 0 33.3333333%;
		-ms-flex: 0 0 33.3333333%;
		max-width: 33.3333333%;
	}
	.team-section .col-md-3 {
		flex: 0 0 25%;
		-ms-flex: 0 0 25%;
		max-width: 25%;
	}
	.team-section .col-md-2 {
		flex: 0 0 20%;
		-ms-flex: 0 0 20%;
		max-width: 20%;
	}
}
@media only screen and (min-width: 1200px) {
	.team-section .col-lg-4 {
		flex: 0 0 33.3333333%;
		-ms-flex: 0 0 33.3333333%;
		max-width: 33.3333333%;
	}
	.team-section .col-lg-3 {
		flex: 0 0 25%;
		-ms-flex: 0 0 25%;
		max-width: 25%;
	}
	.team-section .col-lg-2 {
		flex: 0 0 20%;
		-ms-flex: 0 0 20%;
		max-width: 20%;
	}
}
a,
a.hover,
a:hover,
.contact-widget i {
	color: #6abf4b;
}
.service .carousel-indicators .active {
	background: #6abf4b;
}

#introCarousel .carousel-content h2 {
	padding-left: 15px;
	padding-right: 15px;
}

/* Change the direction of the transition. */
/* @media all and (transform-3d), (-webkit-transform-3d) {
  #carousel-service .carousel-inner > .item.next,
  #carousel-service .carousel-inner > .item.active.right {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  #carousel-service .carousel-inner > .item.prev,
  #carousel-service .carousel-inner > .item.active.left {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
} */

footer {
	background: #393839;
}
.home-section {
	padding: 60px 0;
}